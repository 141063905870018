export const size = {
  mobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1366px',
  desktopS: '1536px',
  desktop: '1920px',
};

export const device = {
  mobile: `screen and (min-width: ${size.mobile})`,
  tablet: `screen and (min-width: ${size.tablet})`,
  laptop: `screen and (min-width: ${size.laptop})`,
  laptopL: `screen and (min-width: ${size.laptopL})`,
  desktopS: `screen and (min-width: ${size.desktopS})`,
  desktop: `screen and (min-width: ${size.desktop})`,
};
