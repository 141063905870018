/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';

import GlobalLayout from './global-style';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './layout.css';
import './bootstrapCustom.scss';

const Layout = ({ pageTitle, children }) => {
  return (
    <GlobalLayout>
      <Header pageTitle={pageTitle} />
      <main id="main-content">{children}</main>
      <Footer />
    </GlobalLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
};

export default Layout;
