import styled, { css } from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';

export const PageHeading = styled.h1.attrs(() => ({ tabIndex: 0 }))`
  font-size: 2rem;
  line-height: 1.3;
  max-width: 18rem;
  margin-bottom: 2.5rem;

  &::before {
    content: '';
    display: block;
    height: 0.25rem;
    width: 3rem;
    border-radius: 0.4rem;
    background: linear-gradient(
      180deg,
      ${colors.secondaryDark},
      ${colors.secondary}
    );
    margin-bottom: 1.6rem;
  }

  @media ${device.mobile} {
    padding-right: 4rem;
    max-width: 100%;
  }

  @media ${device.tablet} {
    text-align: left;
    font-size: 3rem;
    margin-bottom: ${(props) => props.mbtablet};

    &::before {
      margin: unset;
      margin-bottom: 3rem;
      width: 5rem;
      height: 0.4rem;
    }

    /* center align using center props */
    ${(props) =>
      props.align === 'center' &&
      css`
        text-align: center;
        margin: auto;
        margin-bottom: 2rem;

        &::before {
          margin: auto;
          margin-bottom: 1.8rem;
        }
      `}
  }

  @media ${device.laptopL} {
    max-width: 38rem;
    padding-right: 0rem;
    margin-bottom: ${(props) =>
      props.mblaptop ? props.mblaptop : '0rem'} !important;
    font-size: 3.2rem;
  }

  @media ${device.desktop} {
    font-size: 4rem;
    max-width: 46rem;
  }
`;

export const SectionHeading = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  /* center align using center props */
  ${(props) =>
    props.align === 'center' &&
    css`
      @media ${device.mobile} {
        & h2,
        & p {
          text-align: center;
        }

        & h2::before,
        & p {
          margin: auto;
          margin-bottom: 1.8rem;
        }
      }
    `}

  /* for footer heading */
  ${(props) =>
    props.headingOf === 'footer' &&
    css`
      & h2 {
        text-align: center;
        margin: auto;
        margin-bottom: 4rem;
        max-width: 26rem;

        &::before {
          margin: auto;
          display: none;
          margin-bottom: 3rem;
        }

        @media ${device.mobile} {
          &::before {
            display: flex;
          }
        }

        @media ${device.tablet} {
          font-size: 3.3rem;
          text-align: left;
          margin: unset;
          margin-bottom: 10rem;
          &::before {
            margin: unset;
            margin-bottom: 3rem;
          }
        }
      }
    `}
`;

export const TitleText = styled.h2.attrs(() => ({ tabIndex: 0 }))`
  font-size: 2rem;
  line-height: 1.3;
  margin-bottom: 0;

  &::before {
    content: '';
    display: block;
    height: 0.25rem;
    width: 3rem;
    border-radius: 0.25rem;
    margin-bottom: 1.8rem;
    background: linear-gradient(
      180deg,
      ${colors.secondaryDark},
      ${colors.secondary}
    );
  }
  /* maxwidth property */
  ${(props) =>
    props.maxw &&
    css`
      max-width: ${props.maxw};
      margin-left: auto;
      margin-right: auto;
    `}
  /* control highlight border with props */
  ${(props) =>
    props.highlightBorder === false &&
    css`
      &::before {
        display: none;
      }
    `}

  @media ${device.tablet} {
    font-size: ${(props) => (props.font ? props.font : '2.6rem')};
    text-align: ${(props) => (props.center ? 'center' : 'left')};

    &::before {
      width: 5rem;
      height: 0.4rem;
      margin: ${(props) => (props.center ? 'auto' : 'unset')};
      margin-bottom: 1.8rem;
    }
  }

  @media ${device.laptopL} {
    font-size: ${(props) => (props.font ? props.font : '2.8rem')};
  }
`;

export const Description = styled.p.attrs(() => ({ tabIndex: 0 }))`
  font-size: 1rem;
  line-height: 1.6;
  color: ${colors.grey};

  @media ${device.tablet} {
    font-size: 1.25rem;
    text-align: ${(props) => (props.center ? 'center' : 'left')};
    margin: ${(props) => (props.center ? 'auto' : 'unset')};
    margin-bottom: 1.8rem;
    max-width: 56rem;
  }

  @media ${device.laptopL} {
    max-width: 45rem;
  }

  /* center align using center props */
  ${(props) =>
    props.align === 'center' &&
    css`
      @media ${device.tablet} {
        text-align: center;
        margin: auto;
      }
    `}
`;
