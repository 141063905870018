import React from 'react';
import { Link } from 'gatsby';
import { CgFacebook } from '@react-icons/all-files/cg/CgFacebook';
import { CgTwitter } from '@react-icons/all-files/cg/CgTwitter';
import { CgInstagram } from '@react-icons/all-files/cg/CgInstagram';
// TODO: Overserve this https://github.com/astrit/css.gg/issues/69 and once available - replace from CG family below.
import { Linkedin } from 'react-bootstrap-icons';

import {
  FooterWrapper,
  FooterList,
  Location,
  LocationTitle,
  LocationAddress,
  LinkTitle,
  SocialLink,
} from './style';
import Title from '../Title';
import { ROUTES, socialLink, APPVERSION } from '../../config/constants';
import config from '../../config/site-config';

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <div className="container">
          <Title
            title="Technologists, Optimists, Strategists."
            headingOf="footer"
          />
          <div className="row">
            <div className="col-lg-5">
              <FooterList>
                <LinkTitle>Location</LinkTitle>
                <div className="row">
                  {config.companyAddresses.map(
                    ({
                      addressCountry,
                      streetAddress,
                      addressLocality,
                      postalCode,
                    }) => {
                      return (
                        <div className="col" key={addressCountry}>
                          <Location>
                            <LocationTitle>{addressCountry}</LocationTitle>
                            <LocationAddress>
                              {streetAddress} <br />
                              {addressLocality}, {postalCode}
                            </LocationAddress>
                          </Location>
                        </div>
                      );
                    },
                  )}
                </div>
              </FooterList>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col">
                  <FooterList>
                    <LinkTitle>
                      <Link to={ROUTES.sitemap.path}>Sitemap</Link>
                    </LinkTitle>
                    <ul>
                      <li>
                        <Link to={ROUTES.home.path}>
                          {ROUTES.home.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.company.path}>
                          {ROUTES.company.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.work.path}>
                          {ROUTES.work.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.blog.path}>
                          {ROUTES.blog.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.resources.path}>
                          {ROUTES.resources.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.career.path}>
                          {ROUTES.career.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.privacy.path}>
                          {ROUTES.privacy.navTitle}
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.terms.path}>
                          {ROUTES.terms.navTitle}
                        </Link>
                      </li>
                    </ul>
                  </FooterList>
                </div>

                <div className="col">
                  <FooterList>
                    <LinkTitle>Follow us</LinkTitle>
                    <ul>
                      <SocialLink>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={socialLink.facebook.link}
                        >
                          <span>
                            <CgFacebook />
                          </span>
                          <span>{socialLink.facebook.name}</span>
                        </a>
                      </SocialLink>

                      <SocialLink>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={socialLink.instagram.link}
                        >
                          <span>
                            <CgInstagram />
                          </span>
                          <span>{socialLink.instagram.name}</span>
                        </a>
                      </SocialLink>

                      <SocialLink>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={socialLink.twitter.link}
                        >
                          <span>
                            <CgTwitter />
                          </span>
                          <span>{socialLink.twitter.name}</span>
                        </a>
                      </SocialLink>

                      <SocialLink>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={socialLink.linkedin.link}
                        >
                          <span>
                            <Linkedin />
                          </span>
                          <span> {socialLink.linkedin.name}</span>
                        </a>
                      </SocialLink>
                    </ul>
                  </FooterList>
                </div>
                <div className="col d-none d-md-block">
                  <FooterList>
                    <LinkTitle>Our Products </LinkTitle>
                    <ul>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://gharkokaam.com"
                        >
                          GharKo Kaam®
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.lettrly.in"
                        >
                          Lettrly®{' '}
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.danfebooks.com.np"
                        >
                          DanfeBooks®{' '}
                        </a>
                      </li>
                    </ul>
                  </FooterList>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="versionBox">
          <small className="text-center text-muted d-block pb-3">
            Version: {APPVERSION}
          </small>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
