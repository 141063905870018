import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/inter/300.css';

import colors from './globalColors';
import { device } from './device';

const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(180deg, #14172E 0%, #1C213B 8%, #222844 16%, #202540 24%, #161830 32%, #1C213B 40%,  #222844 48%, #202540 56%, #161830 64%, #1C213B 72%, #222844 80%, #202540 88%, #161830 96%, #14172E 90%);
    font-family: "Inter";
    font-weight: 300;
    font-display: swap;
    color:${colors.white};
    overflow-x: hidden;
  }

  h1, h2, h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-display: swap;
  }

  a:hover {
    text-decoration: none;
  }

  * ::selection{
    color: ${colors.white};
    background:#0765da;
  } 

  .MuiDialogContent-root,
  .MuiTypography-root {
    background: ${colors.spaceCadetDark};
    color: ${colors.white};
    border-color: ${colors.spaceCadetDark};

  }
`;

export const Wrapper = styled.section.attrs(() => ({ tabIndex: 0 }))`
  display: flex;
  flex-direction: column;
  padding: 4rem 0 8rem;
  margin: auto;
  position: relative;
  overflow: hidden;

  @media ${device.tablet} {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  @media ${device.laptop} {
    padding: 2rem 0 8rem;
  }
`;

export const TextPost = styled.article.attrs(() => ({ tabIndex: 0 }))`
  font-size: 1.1rem;

  h2 {
    font-size: 2.8rem;

    @media ${device.mobile} {
      font-size: 1.8rem;
    }
  }

  > h2 {
    margin-top: 3rem;
    @media ${device.mobile} {
      margin-top: 2rem;
    }
  }

  h3 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    color: ${colors.grey};
    line-height: 1.6;
  }

  > p {
    max-width: 80%;
  }

  ul,
  ol {
    margin-left: 2rem;

    > li {
      line-height: 2;
    }
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }
`;

export const Footnotes = styled.aside.attrs(() => ({ tabIndex: 0 }))`
  &,
  p {
    font-size: 10px;
  }

  h5 {
    margin-top: 5rem;
    font-size: 12px;
  }
`;

export default function GlobalLayout({ children }) {
  return (
    <React.Fragment>
      <GlobalStyle />
      {children}
    </React.Fragment>
  );
}

GlobalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
