import styled from 'styled-components';

import { device, size } from '../../layout/device';
import colors from '../../layout/globalColors';

export const FooterWrapper = styled.footer.attrs(() => ({ tabIndex: 0 }))`
  color: ${colors.white};
  padding: 5rem 1.25rem 0;
  background: url('/img/footerbg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center top;

  .versionBox {
    @media ${device.laptop} {
      margin-top: 10rem;
    }
  }

  @media ${device.laptop} {
    padding: 21rem 1.25rem 0;
  }

  @media ${device.desktop} {
    max-width: ${size.desktop};
    margin: auto;
    padding-top: 24rem;
  }
`;

export const FooterList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  min-width: 8rem;
  @media ${device.tablet} {
    text-align: left;
  }

  li {
    font-weight: 300;
  }

  a:hover {
    text-decoration: none;
    color: ${colors.secondary};
    border-bottom: 1px dotted;
  }

  li:not(:last-of-type) {
    margin-bottom: 0.75rem;
  }
`;

export const LinkTitle = styled.h3`
  font-size: 1.5rem;
  color: ${colors.white};
  margin-bottom: 1.5rem;

  @media ${device.tablet} {
    margin-bottom: 4rem;
  }
`;

export const SocialLink = styled.li`
  margin-bottom: 0.8rem;

  & a {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media ${device.tablet} {
      justify-content: flex-start;
    }

    & > :first-child {
      display: inline-flex;
      width: 1.5rem;
      height: 1.5rem;
      background: ${colors.spaceCadet};
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
    }

    &:hover {
      border-bottom: 0;
      & > :last-child {
        border-bottom: 1px dotted;
      }
    }
  }

  svg {
    font-size: 0.8rem;
  }

  @media ${device.tablet} {
    svg {
      opacity: 1;
      display: block;
    }
  }
`;

export const Location = styled.div.attrs(() => ({ tabIndex: 0 }))`
  margin-bottom: 2.25rem;
  font-weight: 300;
`;
export const LocationTitle = styled.div`
  font-size: 1.125rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
`;

export const LocationAddress = styled.address`
  max-width: 18rem;
  min-width: 15rem;

  @media ${device.tablet} {
    min-width: 18rem;
  }
`;
