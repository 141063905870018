module.exports = {
  siteTitle: 'Optimum Futurist - A Software Engineering Consultancy',
  appName: 'Optimum Futurist',
  appLegalName: 'Optimum Futurist Tech Pvt. Ltd.',
  siteTitleAlt: 'Optimum Futurist - A Software Engineering Consultancy', // Alternative site title for SEO.
  siteDescription:
    'Optimum Futurist help businesses and brands build their digital systems and products by providing reliable development teams and experienced consultants.', // Website description used for RSS feeds/meta description tag.
  siteMetaKeywords: 'Optimum Futurist, Blog, Javascript',
  sitePreviewImage: '/img/optimum-futurist-website-preview.png', // Logo used for SEO and manifest.
  siteLogoSquare: '/img/logo-lg.png', // Logo used for SEO and manifest.
  siteUrl: 'https://optimumfuturist.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
  googleGA4Id: 'G-27QPZ5J03D', // GA tracking ID.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  blogPostsPerPage: 10, // Amount of posts displayed per listing page.
  resourcesPerPage: 10, // Amount of press release posts displayed per listing page.
  caseStudiesPerPage: 10, // Amount of press release posts displayed per listing page.
  userName: 'optimumfuturist', // Username to display in the author segment.
  userEmail: 'hello@optimumfuturist.com', // Email used for RSS feed's author segment
  userTwitter: '@optimumfuturist', // Optionally renders "Follow Me" in the UserInfo segment.
  userDescription:
    'Optimum Futurist help businesses and brands build their digital systems and products by providing reliable development teams and experienced consultants.', // User description to display in the author segment.
  copyright:
    'Copyright © 2016 - 2021. Optimum Futurist Tech Pvt. Ltd. - All rights reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '', // Used for setting manifest and progress theme colors.
  backgroundColor: '', // Used for setting manifest background color.
  companyAddresses: [
    {
      streetAddress: 'Philadelphia Pike',
      addressLocality: 'Claymont',
      addressRegion: 'DE',
      postalCode: '19703',
      addressCountry: 'USA',
    },
    {
      streetAddress: 'Saraswati Marga, Thapagaun, New Baneshwor',
      addressLocality: 'Kathmandu',
      addressRegion: 'Bagmati',
      postalCode: '44600',
      addressCountry: 'NEPAL',
    },
  ],
  phoneNumber: '+977-1-5245009',
  schemaSameAs: [
    'https://www.facebook.com/optimumfuturist',
    'https://www.instagram.com/optimum_futurist/',
    'https://twitter.com/optimumfuturist',
    'https://www.linkedin.com/company/optimumfuturist',
    'https://github.com/optimumfuturist',
    'https://www.crunchbase.com/organization/optimum-futurist',
  ],
};
