import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ROUTES } from '../../config/constants';
import logo from '../../../static/logo.svg';
import {
  HeaderWrapper,
  NavBrand,
  Navbar,
  NavToggler,
  NavBackdrop,
} from './style';

const Header = (props) => {
  const [mobileMenu, setmobileMenu] = React.useState(false);
  const [stickyNav, setStickyNav] = React.useState(false);

  const openMobileMenu = () => {
    setmobileMenu(!mobileMenu);
  };

  React.useEffect(function mount() {
    function onScroll() {
      // eslint-disable-next-line no-undef
      let checkNav = window.scrollY > 10;
      checkNav ? setStickyNav(true) : setStickyNav(false);
    }
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-undef
      window.addEventListener('scroll', onScroll, true);
    }
  });

  return (
    <HeaderWrapper stickyNav={stickyNav}>
      <div className="container">
        <NavBrand>
          <Link to={ROUTES.home.path}>
            <img
              src={logo}
              alt={ROUTES.home.navTitle}
              width="196"
              height="84"
              role="button"
              aria-label="This is the official logo of Optimum Futurist"
            />
            <span>{props.pageTitle}</span>
          </Link>
        </NavBrand>

        <Navbar mobileMenu={mobileMenu}>
          <h2 className="sr-only">Main Navbar</h2>
          <ul>
            <li className="skip-nav-box">
              <a className="skip-nav-link" href="#main-content">
                Skip to content
              </a>
            </li>
            <li>
              <Link to={ROUTES.home.path} activeClassName="active">
                {ROUTES.home.navTitle}
              </Link>
            </li>
            <li>
              <Link to={ROUTES.company.path} activeClassName="active">
                {ROUTES.company.navTitle}
              </Link>
            </li>
            <li>
              <Link to={ROUTES.work.path} activeClassName="active">
                {ROUTES.work.navTitle}
              </Link>
            </li>
            <li>
              <Link to={ROUTES.blog.path} activeClassName="active">
                {ROUTES.blog.navTitle}
              </Link>
            </li>
            <li>
              <Link to={ROUTES.resources.path} activeClassName="active">
                {ROUTES.resources.navTitle}
              </Link>
            </li>
            <li>
              <Link to={ROUTES.career.path} activeClassName="active">
                {ROUTES.career.navTitle}
              </Link>
            </li>
          </ul>
        </Navbar>

        <NavBackdrop mobileMenu={mobileMenu} onClick={openMobileMenu} />

        <NavToggler mobileMenu={mobileMenu} onClick={openMobileMenu}>
          <div></div>
          <div></div>
          <div></div>
        </NavToggler>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  pageTitle: PropTypes.string,
};

export default Header;
