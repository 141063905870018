const colors = {
  black: '#000000',
  white: '#ffffff',
  grey: '#f8f8f8',
  error: '#f44336',
  lightGrey: '#7A96AA',
  primary: '#242B47',
  primaryDark: '#14162D',
  secondaryDark: '#F4D293',
  secondary: '#CDA04B',
  cadetBlue: '#B0B6CA',
  neonBlue: '#5258ff',
  crayolaBlue: '#6880FF',
  crayolaGold: '#E9C47F',
  spaceCadet: '#3C476C',
  spaceCadet2: '#2A2D4F',
  spaceCadetDark: '#2D3653',
  spaceCadet2Dark: '#1B1D34',
};

export default colors;
