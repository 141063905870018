import React from 'react';
import PropTypes from 'prop-types';

import { PageHeading, SectionHeading, TitleText, Description } from './style';

const Title = (props) => {
  if (props.headingOf === 'page') {
    return (
      <>
        <PageHeading
          align={props.align}
          mbtablet={props.mbtablet}
          mblaptop={props.mblaptop}
        >
          {props.title}
        </PageHeading>
        {props.desc ? (
          <Description align={props.align}>{props.desc}</Description>
        ) : (
          ''
        )}
      </>
    );
  } else {
    return (
      <SectionHeading align={props.align} headingOf={props.headingOf}>
        <TitleText
          highlightBorder={props.highlightBorder}
          font={props.font}
          maxw={props.maxw}
        >
          {props.title}
        </TitleText>
        {props.desc ? <Description>{props.desc}</Description> : ''}
      </SectionHeading>
    );
  }
};

export default Title;

Title.propTypes = {
  align: PropTypes.string,
  headingOf: PropTypes.string,
  font: PropTypes.string,
  maxw: PropTypes.string,
  highlightBorder: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  mbtablet: PropTypes.string,
  mblaptop: PropTypes.string,
};
