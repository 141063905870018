import styled, { css } from 'styled-components';
import { device } from '../../layout/device';
import colors from '../../layout/globalColors';

export const HeaderWrapper = styled.header`
  padding: 1.5rem 1.25rem;
  position: sticky;
  z-index: 3;
  top: 0;
  transform: translateY(0%);
  transition: transform 0.3s ease-in;
  background: #14172e;

  ${(props) =>
    props.stickyNav &&
    css`
      background: #202540;
      box-shadow: 0 0 4rem rgb(91, 108, 255, 0.12);
      transition: transform 0.3s ease-in;
    `}

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${device.laptop} {
    padding: 0.8rem 1.25rem;
  }
`;

export const Navbar = styled.nav`
  & ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${colors.grey};
    z-index: 3;
    min-width: 60vw;
    margin-left: 0;
    margin-bottom: 0rem;
    padding: 1rem;
    transition: transform 0.3s ease-in-out;

    // collapse while clicking button
    transform: ${({ mobileMenu }) => {
      return mobileMenu ? `translateX(0)` : `translateX(-100%)`;
    }};

    &:after {
      content: '';
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: red;
    }

    & li a {
      display: inline-block;
    }

    & li a {
      display: inline-block;
      padding: 0.75rem 0;
      color: ${colors.primaryDark};
      position: relative;
    }

    & li a:hover {
      text-decoration: none;
    }

    & li a.active::after {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      display: flex;
      position: absolute;
      border-radius: 50%;
      background: linear-gradient(
        90deg,
        ${colors.crayolaBlue},
        ${colors.neonBlue}
      );
      box-shadow: 0 0 1.25rem rgb(91, 108, 255, 0.5);
      top: 0.8rem;
      right: -1.4rem;
    }

    & li.skip__link {
      display: none;
    }

    & li.skip__link--visible {
      display: inline-block;
    }

    @media ${device.tablet} {
      flex-direction: row;
      position: relative;
      height: auto;
      background-color: transparent;
      transform: translateX(0);
      transition: none;
      min-width: auto;

      & li a {
        color: ${colors.white};
        border: 2px solid transparent;
        padding: 0.75rem;

        &.active::after {
          display: none;

          @media ${device.laptop} {
            display: flex;
            content: '';
            width: 100%;
            height: 100%;
            animation: activeBorderAnimate 0.5s ease-out;
            border-radius: 3rem;
            border: 2px solid ${colors.white};
            background: transparent;
            box-shadow: none;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in;
            transform-origin: 0 0;
          }
        }

        &::after {
          content: '';
          width: 0rem;
          height: 100%;
          border-radius: 0;
          border-bottom: 2px solid ${colors.white};
          background: transparent;
          box-shadow: none;
          top: 50%;
          left: 0%;
          position: absolute;
          transform: translate(-50%, -50%);
          transition: all 0.3s ease-in;
        }
      }

      li a {
        position: relative;
      }

      li a::after {
        transition: all 0.3s ease-in;
      }

      /* blue gradient dot that only appears in mobile devices */
      li:hover a:not(.active)::after {
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        display: flex;
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(
          90deg,
          ${colors.crayolaBlue},
          ${colors.neonBlue}
        );
        box-shadow: 0 0 1.25rem rgb(91, 108, 255, 0.5);
        top: 0.8rem;
        right: 0rem;
        transition: all 0.8s ease-in;
      }

      @media ${device.laptop} {
        li:hover a:not(.active)::after {
          /* display: none; */
        }

        & li:hover a:not(.active) {
          /* color: ${colors.secondary};
          border: 2px solid ${colors.white};
          border-radius: 1.8rem; */

          &::after {
            content: '';
            width: calc(100% - 2rem);
            height: 100%;
            border-radius: 0;
            border-bottom: 2px solid ${colors.white};
            background: transparent;
            box-shadow: none;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            transition: all 0.3s ease-in;
            transform-origin: 0 0;
          }
        }
      }
    }

    @media ${device.laptop} {
      margin: 0 -0.6rem;
      & li a {
        padding: 0.6rem 1.25rem;
        margin: 0 0.6rem;
      }
    }

    @keyframes activeBorderAnimate {
      0% {
        background-size: 0 0;
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left: transparent;
        border-right-color: transparent;
      }
      16% {
        background-size: 2px 100%;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: white;
        border-left-color: transparent;
      }
      32% {
        background-size: 32% 100%;
        border-right-color: white;
      }
      48% {
        background-size: 48% 100%;
        border-top-color: white;
      }
      64% {
        background-size: 60% 100%;
      }
      100% {
        background-size: 100% 100%;
        border-left-color: white;
      }
    }
  }

  @media ${device.tablet} {
    .skip-nav-box {
      position: relative;
      min-width: 10rem;
    }
    .skip-nav-link {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;

      &:focus {
        color: white;
        clip: auto;
        height: auto;
        left: 10px;
        padding: 1rem;
        position: absolute;
        top: 0px;
        width: auto;
        z-index: 1001;
      }
    }
  }
`;

export const NavToggler = styled.div`
  @media ${device.tablet} {
    display: none;
  }

  button:focus {
    outline: none;
  }
  margin: -4px 0;

  & div {
    width: 0.95rem;
    height: 2px;
    margin: 4px 0;
    transition: 0.4s;
    background-color: ${colors.white};

    &:nth-of-type(2) {
      transform: translateX(-4px);
    }
  }

  ${({ mobileMenu }) =>
    mobileMenu &&
    `
      & div:first-of-type{
    transform: rotate(-45deg) translate(-1px, 6px);
  }

  & div:nth-of-type(2){
    opacity:0
  }

  & div:nth-of-type(3){
    transform: rotate(45deg) translate(-2px, -8px);
  }
  `}
`;

export const NavBrand = styled.h1`
  display: flex;
  margin-bottom: 0;
  margin-left: -0.5rem;
  min-width: 10rem;

  & a {
    display: flex;
    align-items: center;
  }

  & span {
    display: none;
  }
`;

export const NavBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: ${colors.primaryDark};
  opacity: 0.8;
  position: fixed;
  left: 0;
  top: 0;
  display: ${({ mobileMenu }) => {
    return mobileMenu ? 'flex' : 'none';
  }};
`;
