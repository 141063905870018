/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const config = require('../../config/site-config');

function SEO({ description, meta, metaKeywords, title, lang }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata.title;
  // const appName = site.siteMetadata.appName;
  const image = site.siteMetadata.image;
  const siteURL = site.siteMetadata.siteURL;

  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': 'https://corporation.optimumfuturist.com',
      name: config.appName,
      legalName: config.appLegalName,
      description: config.siteDescription,
      url: config.siteUrl,
      address: {
        '@type': 'PostalAddress',
        ...config.companyAddresses[0],
      },
      sameAs: config.schemaSameAs,
      image,
      logo: config.siteLogoSquare,
      email: config.userEmail,
      telephone: config.phoneNumber,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : defaultTitle}
      titleTemplate={title ? `%s | ${config.appName}` : defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:url`,
          content: siteURL,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `twitter:url`,
          content: siteURL,
        },
      ].concat(meta)}
      links={[
        {
          rel: `canonical`,
          href: siteURL,
        },
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  title: '',
  description: ``,
  metaKeywords: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  metaKeywords: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
