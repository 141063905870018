const ROUTES = {
  home: {
    path: '/',
    navTitle: 'Home',
    title: '',
    desc: 'We take your business into the digital age. We build powerful digital systems that help transform businesses and organizations.',
  },
  company: {
    path: '/company',
    navTitle: 'Company',
    title: 'About us and our Company',
    desc: 'Optimum Futurist is a dynamic IT consulting firm that lives on the cutting edge. We provide exceptional software development, design, and enterprise consulting services.',
  },
  work: {
    path: '/work',
    navTitle: 'Work',
    title: 'Our Work',
    desc: "We're an agile IT consulting firm with dozens of years of experience. View our work to see how we deliver high-quality, efficient software solutions for less.",
  },
  blog: {
    path: '/blogs',
    navTitle: 'Blog',
    title: 'Engineering Blog',
    desc: "Grow your digital skills and improve your online presence with Optimum Futurist's critically acclaimed blog on IT consulting services.",
  },
  resources: {
    path: '/resources',
    navTitle: 'Resources',
    title: 'Free, Open Source Resources, Templates and Projects',
    desc: 'On the hunt for IT resources? Our team happily provides in-depth time-saving resources for your business for free or at highly reasonable prices.',
  },
  casestudies: {
    path: '/case-studies',
    navTitle: 'Case Studies',
    title: 'Case Studies of Our Projects',
    desc: 'Read about in-depth analysis, our learnings, methodologies, and approaches for some of the successful projects that we have delivered.',
  },
  career: {
    path: '/careers',
    navTitle: 'Careers',
    title: 'Careers',
    desc: 'Want to work in a fast-paced growth environment? Optimum Futurist is hiring. See how we can help you achieve your ideal career outcomes today!',
  },
  privacy: {
    path: '/privacy-policy',
    navTitle: 'Privacy Policy',
    title: 'Privacy Policy',
    desc: 'Privacy policies is applicable and you agree to our pocilies when you visit our website',
  },
  terms: {
    path: '/terms-of-services',
    navTitle: 'Terms of Service',
    title: 'Terms of Service',
    desc: 'Terms of services for this website visit',
  },
  sitemap: {
    path: '/sitemap/sitemap-index.xml',
    navTitle: 'Sitemap',
    title: 'Sitemap Index',
    desc: 'A machine readable sitemap for search engines',
  },
};

const socialLink = {
  facebook: {
    name: 'Facebook',
    link: 'https://www.facebook.com/optimumfuturist',
  },
  instagram: {
    name: 'Instagram',
    link: 'https://www.instagram.com/optimum_futurist',
  },
  twitter: { name: 'Twitter', link: 'https://www.twitter.com/optimumfuturist' },
  linkedin: {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/optimumfuturist',
  },
};

import pkg from '../../package.json';

const APPVERSION = pkg.version;

const captcha_key = 'aa047409-8f78-41ff-b272-e3814bb7f34f';

const Teams = [
  {
    id: 1,
    name: 'Shekhar Sharma',
    designation: 'Co-Founder, Lead Developer',
    img: '/img/team1.webp',
    email: 'mailto:shekhar@optimumfuturist.com',
    linkedin: 'https://www.linkedin.com/in/absqueued/',
  },
  {
    id: 3,
    name: 'Diwan Adhikari',
    designation: 'Co-Founder, General Manager',
    img: '/img/team3.webp',
    email: 'mailto:diwan@optimumfuturist.com',
    linkedin: 'https://www.linkedin.com/in/diwanadhikari/',
  },
];

const companyStats = [
  { id: '1', cardtitle: '27+', carddesc: 'Projects delivered' },
  { id: '2', cardtitle: '6', carddesc: 'Startups MVP built' },
  { id: '3', cardtitle: '14+', carddesc: 'Years of expertise' },
];

const FrontendTech = [
  {
    id: 1,
    img: '/img/icons/icon-css.svg',
    title: 'css',
    desc: 'Used to build the aesthetics of your project, we utilize CSS extensively for both UI and UX design.',
  },
  {
    id: 2,
    img: '/img/icons/icon-react.svg',
    title: 'ReactJS',
    desc: 'React is one of the most popular (and highly extensible) frontend frameworks, and our engineers are experts at building fluid, robust React apps.',
  },
  {
    id: 3,
    img: '/img/icons/icon-vue.svg',
    title: 'Vue.JS',
    desc: "We're Vue.js pros, and for a good reason - Vue.js is quickly gaining popularity as a performant frontend framework perfect for any use case.",
  },
  {
    id: 4,
    img: '/img/icons/icon-angular.svg',
    title: 'Angular',
    desc: 'An opinionated and efficient framework, Angular is one of the backbones of the modern Web. Our developers have built hundreds of successful Angular projects.',
  },
  {
    id: 5,
    img: '/img/icons/icon-scss.svg',
    title: 'SCSS',
    desc: 'A special language created to make CSS programming easy. Eventually - its just CSS for webpage styling.',
  },
];

const BackendTech = [
  {
    id: 1,
    img: '/img/icons/icon-node.svg',
    title: 'NodeJS',
    desc: 'Our engineers are Node JS experts. Built as the backend version of JavaScript, Node JS is quickly growing into a dominant, robust player in modern web development. ',
  },
  {
    id: 2,
    img: '/img/icons/icon-php.svg',
    title: 'PHP',
    desc: 'One of the most popular languages of the past decade, PHP is your reliable, get-it-done backend workhorse. Our team knows the ins and outs of PHP and can maintain or scale any product with reliability.',
  },
  {
    id: 3,
    img: '/img/icons/icon-mysql.svg',
    title: 'MySQL',
    desc: 'The database of choice for many small to enterprise-level projects, our professionals know MySQL from the ground up.',
  },
  {
    id: 4,
    img: '/img/icons/icon-wordpress.svg',
    title: 'Wordpress',
    desc: 'As they say it "42% of the web is built on WordPress." This is the content management system for the web.',
  },
];

const MobilTech = [
  {
    id: 1,
    img: '/img/icons/icon-swift.svg',
    title: 'Swift',
    desc: "Swift is Apple's fast, efficient language for mobile programming apps, and it's one of the dominant players on the App store. So if you want your mobile app on iOS (or Android), we have the Swift know-how to get it done.",
  },
  {
    id: 2,
    img: '/img/icons/icon-flutter.svg',
    title: 'Flutter',
    desc: "Flutter has made waves recently as a high-quality, open-source UI kit for both Android and iOS apps. We've taken it upon ourselves to become Flutter pros and quickly build, maintain, or fix Flutter apps.",
  },
  {
    id: 3,
    img: '/img/icons/icon-react.svg',
    title: 'React Native',
    desc: 'The "mobile app" version of traditional React, React Native, is used by hundreds of thousands of popular applications worldwide and delivers fast, efficient user interaction. Need a React Native app built or maintained? We\'re the team for the job!',
  },
];

export {
  ROUTES,
  APPVERSION,
  socialLink,
  Teams,
  companyStats,
  FrontendTech,
  BackendTech,
  MobilTech,
  captcha_key,
};
